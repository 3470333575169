import React from "react"
import styled from "styled-components"
import { ReactComponent as BackImage } from "../../images/svgs/privacy_terms/Back.svg"
import { Link } from "gatsby"

import { SectionContainer, Section } from "../global"

const PrivacyPolicy = () => (
  <StyledSection>
    <SectionContainer>
      <ButtonWrapper>
        <Link to="/"><BackButton> <BackImage /> </BackButton></Link>
      </ButtonWrapper>
      <HeaderTextWrapper>
        <h1>Privacy Policy</h1>
      </HeaderTextWrapper>
      <BodyTextWrapper>
        <h6>September 2021</h6>

        <p>
          This Privacy Policy governs your use of this site and is by and
          between Booking Bucket, LLC (referred to herein as &ldquo;Booking
          Bucket&rdquo;, &ldquo;Company&rdquo;, &ldquo;we&rdquo;,
          &ldquo;us&rdquo;, or &ldquo;our&rdquo;) and you, the user, on behalf
          of yourself and the user, customer or supplier (herein referred to as
          &ldquo;you&rdquo; or &ldquo;your&rdquo;) for which you are visiting
          this Site.
        </p>

        <p>
          This page is used to inform website visitors regarding our policies
          concerning the collection, use, and disclosure of Personal Information
          if you decide to request information, services or support services
          through our Booking Bucket website.
          <br />
          You do not have to give us personal information to visit our website.
        </p>

        <h4>Information collection and use</h4>

        <p>
          We collect personally identifiable information only if specifically
          and knowingly provided by you. This Privacy Policy statement explains
          what information we gather, how we gather it and how we use it.
        </p>

        <p>
          <ul>
            <li>What information we gather;</li>
            <li>How we gather information;</li>
            <li>How we use the information;</li>
            <li>With whom we may share information;</li>
            <li>Security of the information;</li>
            <li>Storage of the information;</li>
            <li>Your rights and choices; and</li>
            <li>Contact Information.</li>
          </ul>
        </p>

        <p>
          Please note that we may update this statement without notice from time
          to time by posting the updated terms on our websites. You are
          responsible for periodically reading this statement. If you use our
          websites after we have updated this statement, you acknowledge that
          you have read the updated terms and consent to our revised privacy
          practices.
        </p>

        <p>
          By becoming a registered member or otherwise using our websites, you
          acknowledge that you have read this privacy policy statement and
          consent to our privacy practices as described in this statement,
          including our use and disclosure of personally identifiable
          information for the purposes described below.
        </p>

        <p>
          If you or your customers are residents of a country in the European
          Union, Iceland, Liechtenstein, Norway or the United Kingdom, please
          read this Privacy Policy to understand additional rights you or your
          customers may have pursuant to the General Data Protection Regulation
          (GDPR).
        </p>

        <p>
          If you are a resident of the State of California, please also read
          Your California Privacy Rights below to understand additional rights
          you may have pursuant to California Civil Code Section 1798.83 and the
          California Consumer Privacy Act of 2018.
        </p>

        <h5>Information we gather and track</h5>

        <p>
          We may collect two types of information from you and other registered
          users of our websites: (1) &ldquo;personally identifiable
          information&rdquo; and (2) &ldquo;non-personally identifiable
          information&rdquo;.
        </p>

        <p>
          &ldquo;Personally identifiable information&rdquo; is information that
          identifies you personally, such as your name, address, telephone
          number, email address, or company name.
        </p>

        <p>
          &ldquo;Non-personally identifiable information&rdquo; can be technical
          information, such as aggregated tracking information derived mainly by
          tallying page views throughout our site, or it can be demographic
          information. Non-personally identifiable information does not identify
          you personally. If you do provide us with non-personally identifiable
          information, we may use it for the purposes described in this
          statement or for any other legal purpose.
        </p>

        <p>
          We do not offer services directed to children. Should an individual
          whom we know to be a child under age 18 send personally identifiable
          information to us, we will take measures to remove that user&rsquo;s
          personal information from our databases. From time to time we may come
          into contact with persons under the age of 18 as may be necessary for
          the performance of our contractual obligations. In such instances, we
          take reasonable efforts to not collect or store any personally
          identifiable data of such persons, but rather de-identify and
          anonymize such data of persons under the age of 18.
        </p>

        <h5>How we gather and track information</h5>

        <h7>Personally Identifiable Information</h7>
        <br />
        <br />

        <p>
          As part of the registration process, we collect your company and/or
          trade name, contact name, telephone number, principal address and
          email address.
        </p>

        <p>
          We may use and store information about your location if you give us
          permission to do so (&ldquo;Location Data&rdquo;). We use this data to
          provide features of our Service, to improve and customize our Service.
          You can enable or disable location services when you use our Service
          at any time, through your device settings.
        </p>

        <p>
          As part of the utilization of the Booking Bucket services by you, we
          store in our databases certain information regarding your customers
          and prospective customers, including name, telephone number and/or
          email address. In some cases, individual preferences and requirements
          will also be maintained as part of the customer or potential customer
          profile.
        </p>

        <p>
          From time to time visitors to our websites who are not registered
          users may request information about our sites and services and provide
          their name and email address. We collect and retain that information.
        </p>
        <br />
        <br />

        <h7>Non-Personally Identifiable Information</h7>
        <br />
        <br />

        <p>
          Cookies. We may place a text file called a &ldquo;cookie&rdquo; in the
          browser files of your computer (if you or your browser accept the
          cookie) to collect information about your activity on our websites.
          The cookie itself does not contain personally identifiable
          information. A cookie can&rsquo;t read data off your hard disk or read
          cookie files created by other sites. You can refuse cookies by turning
          them off in your browser but if you do, you may not have access to
          some areas of our websites, or to the personalized features of our
          websites. You may also set your browser to warn you before accepting
          cookies.
        </p>

        <p>
          Log Files. As is true of most websites, we gather certain information
          automatically and store it in log files. This information includes
          internet protocol (IP) addresses, browser type, internet service
          provider (ISP), referring/exit pages, operating system, date/time
          stamp and clickstream data.
        </p>

        <p>How we use Information we gather and track</p>

        <p>Personally Identifiable Information</p>

        <p>
          We use personally identifiable information that we gather from
          registered users and store in our databases (whether the personally
          identifiable information relates to the registered user or any
          customers or potential customers of the registered user) solely to
          provide the service and functionality for which registered users
          subscribe. Under no circumstances do we divulge any personally
          identifiable information to any other registered user or to any third
          party. So, for example, we use your personally identifiable
          information:
        </p>

        <p>
          To authorize your access to appropriate services, pages, screens and
          data in our system;
          <br />
          To send any materials including promotional materials to your
          customers and potential customers that you have requested that we send
          on your behalf;
          <br />
          To communicate with you regarding our sites and services, new services
          and changes to our sites and services that we may make from time to
          time;
          <br />
          To provide customer and technical service to you;
          <br />
          To comply with the law, or in the good faith belief that such action
          is necessary to conform to the requirements of the law, or comply with
          legal process served on us, and to protect and defend our rights or
          property, including our rights and property and our websites, or act
          in urgent circumstances to protect the personal safety of you and our
          other visitors; and
          <br />
          To protect against fraud or potential fraud.
        </p>

        <p>
          We use names and email addresses provided by visitors to our websites
          who expressly request information from us in order to provide the
          requested information to them.
        </p>

        <p>Non-Personally Identifiable Information</p>

        <p>
          We use non-personally identifiable information that we gather and
          track to analyze trends, administer the site, track users&rsquo;
          movements around the site and gather demographic information about our
          user base as a whole.
        </p>

        <p>
          We do not link this automatically collected data to personally
          identifiable information.
        </p>

        <p>
          We may create aggregate reports on user demographics and traffic
          patterns for advertisers, sponsors, and partners. This allows our
          partners to be more effective and allows our users to receive
          information that is pertinent to their needs.
        </p>

        <p>
          We will not divulge or share any financial or accounting-related data
          input by our registered users and stored in our databases to any other
          registered user or to any third party at any time.
        </p>

        <h5>With whom we may share information</h5>

        <p>
          We may share, as controller or as processor providing services at the
          direction of our clients, your personal data to third parties who
          perform services on our behalf, including our technology providers,
          payment card processors, administrative personnel and providers and
          professional advisors.
        </p>

        <p>
          Booking Bucket uses a limited number of third-party service providers
          to assist us in providing our services to customers. These third-party
          providers assist with the transmission of data and provide data
          storage services. These third parties may access, process, or store
          personal data in the course of providing their services. Booking
          Bucket maintains contracts with these third parties restricting their
          access, use and disclosure of personal data.
        </p>

        <p>
          We use either an outside payment (credit/debit card and bank
          transfers) processing company for subscription services and for point
          of sale functionality. The payment processing company does not retain,
          share, store or use your personally identifiable information for any
          other purposes, however, we cannot guarantee that there will not be a
          security breach of the payment processing company.
        </p>

        <p>
          We may disclose information if we have a good faith belief that
          disclosure is necessary by law or the legal process, to protect and
          defend our or others&rsquo; interests or property, or to enforce
          agreements you or our clients enter into with us. Booking Bucket may
          be required to disclose personal information in response to lawful
          requests by public authorities, including to meet national security or
          law enforcement requirements.
        </p>

        <p>
          We may obtain your written consent from time to time in electronic
          form by using online agreements or other acknowledgements through our
          application, including for any other contemplated uses of your
          personal data not addressed in this Privacy Policy. Please read all
          online agreements carefully before accepting them.
        </p>

        <p>
          In the event Booking Bucket goes through a business transition, such
          as a merger, acquisition by another company, or sale of all or a
          substantial portion of its assets, your personally identifiable
          information will likely be among the assets transferred.
        </p>

        <h5>Security of the information</h5>

        <p>
          We follow generally accepted industry standards to protect the
          personally identifiable information as well as the financial data
          submitted to us, both during transmission and once we receive it. No
          method of transmission over the Internet, or method of electronic
          storage, is 100% secure. Therefore, while we strive to use
          commercially acceptable means to protect your information, we cannot
          guarantee its absolute security.
        </p>

        <p>
          If we learn of a breach of our security system or processes, we may
          attempt to notify you electronically so that you can take appropriate
          protective steps. By using our websites, or providing personally
          identifiable information to us through them, you agree that we can
          communicate with you electronically regarding security, privacy, and
          administrative issues relating to your use of our websites. In the
          event of a breach, we may post a notice on our websites and/or send
          you an email at the email address you provided.
        </p>

        <p>
          YOU MAY HAVE ADDITIONAL RIGHTS TO RECEIVE WRITTEN NOTICE OF SECURITY
          BREACHES UNDER APPLICABLE LAW OF YOUR JURISDICTION.
        </p>

        <h5>Storage of Information</h5>

        <p>
          We retain the personal data we collect for so long as reasonably
          necessary to fulfill the purposes for which the data was collected, to
          perform our contractual and legal obligations, and for any applicable
          statute of limitations periods for the purposes of bringing and
          defending claims.
        </p>

        <h5>Legal Basis for Processing</h5>

        <p>
          We rely on the following legal grounds to process your personal
          information: consent. We may use your personal data as described in
          this Privacy Policy subject to your consent.
        </p>

        <h5>Your rights and choices</h5>

        <p>
          We communicate with our registered members on a regular basis via
          email. For example, we may use your email address to confirm your
          request, to send you notice of payments, to send you information about
          changes to our products and services, and to send notices and other
          disclosures as required by law. Users can opt out of all email
          communications from Booking Bucket by contacting us. We will process
          your unsubscribe request as soon as possible, but please be aware that
          in some circumstances you may receive a few more messages until your
          request is processed. You also may opt out of receiving such emails by
          clicking on the &ldquo;unsubscribe&rdquo; link within the text of the
          applicable email.
        </p>

        <p>
          You may contact us to request information about the personal data we
          have collected from you and to request the correction, modification or
          deletion of such personal information, which for the requests, we will
          do our best to honour subject to any legal and contractual
          obligations.
        </p>

        <p>
          Our Director for Compliance is responsible for our privacy programs.
          <br />
          For information about the personal data we have collected from you and
          to request the correction, modification or deletion of such personal
          information, please email hello@bookingbucket.com
        </p>

        <p>
          Subject to local law, you may have additional rights under the laws of
          your jurisdiction regarding your personal data, such as the right to
          complain to your local data protection authority.
        </p>

        <p>
          Data processed: The Company provides online tools that our customers
          use to operate their services businesses, including by providing
          access to certain of those tools to their own customers. In providing
          these tools, the Company processes data our customers and our
          customers&rsquo; customers submit to our web services or instruct us
          to process on their behalves. While Company&rsquo;s customers and
          their customers&rsquo; customers decide what data to submit, it
          typically includes information about their customers, sales prospects,
          point of sale services, inventory management, and goods ordering.
        </p>

        <p>
          Purposes of data processing: the Company processes data submitted by
          our customers and our customers&rsquo; customers for the purpose of
          providing the Company&rsquo;s online services to them. To fulfill
          these purposes, the Company may access the data to provide the
          services, to correct and address technical or service problems, or to
          follow instructions of the customer who submitted the data, or in
          response to contractual requirements.
        </p>

        <p>
          Inquiries and complaints: If you believe the Company maintains your
          personal data in the Company web services within the scope of the GDPR
          or applicable law of another jurisdiction, you may direct any
          inquiries or complaints concerning our compliance to our compliance
          officer noted above. The Company will respond within 40 days. We are
          committed to respond to complaints and to provide appropriate recourse
          at no cost to you.
        </p>

        <p>
          Third parties who may receive personal data: The Company uses a
          limited number of third-party service providers to assist us in
          providing our services to customers. These third-party providers
          assist with the transmission of data and provide data storage
          services. These third-parties may access, process, or store personal
          data in the course of providing their services. The Company maintains
          contracts with these third-parties restricting their access, use and
          disclosure of personal data in compliance with our obligations, and
          the Company may be liable if they fail to meet those obligations and
          we are responsible for the event giving rise to the damage.
        </p>

        <p>
          Your rights to access, to limit use, and to limit disclosure:
          Residents of the EU, Iceland, Liechtenstein and Norway and certain
          other jurisdictions have rights to access personal data about them,
          and to limit the use and disclosure of their personal data. The
          Company has committed to respect those rights. Because the Company
          personnel have limited ability to access data our customers submit to
          our services, if you wish to request access, to limit use, or to limit
          disclosure, please provide the name of the Company customer who
          submitted your data to our services. We will refer your request to
          that customer and will support them as needed in responding to your
          request.
        </p>

        <p>
          Compelled disclosure: The Company may be required to disclose personal
          information in response to lawful requests by public authorities,
          including to meet national security or law enforcement requirements.
        </p>

        <h5>Third-Party Websites and Advertisers</h5>

        <p>
          Our websites may contain links to third-party websites. While we
          endeavour to work with third parties that share our respect for user
          privacy, we are not responsible for the websites or privacy practices
          of such third parties. We may also use third-party advertisers, ad
          networks, and other advertising, marketing, and promotional companies,
          to serve advertisements on our websites. Such third parties may gather
          information about your visit to our websites or other websites,
          monitor your access to or market products or services to you, monitor
          the ads you view, click-on, or interact with, when they were
          delivered, and the screens and pages that they are on.
          <br />
          We do not endorse these parties, their content, or any products and
          services they offer. You are responsible for knowing when you are
          leaving our website to visit a third-party website, and for reading
          and understanding the terms of use and privacy policy statements for
          each such third party.
        </p>

        <p>
          California Residents - Your Privacy Rights
          <br />
          California Information - Sharing Disclosure
        </p>

        <p>(As provided by California Civil Code Section 1798.83)</p>

        <p>
          A California resident who has provided personal information to a
          business with whom he/she has established a business relationship for
          personal, family, or household purposes (&ldquo;California
          customer&rdquo;) is entitled to request information about whether the
          business has disclosed personal information to any third parties for
          the third parties&rsquo; direct marketing purposes. In general, if the
          business has made such a disclosure of personal information, upon
          receipt of a request by a California customer, the business is
          required to provide a list of all third parties to whom personal
          information was disclosed in the preceding calendar year, as well as a
          list of the categories of personal information that were disclosed.
        </p>

        <p>
          However, under the law, a business is not required to provide the
          above-described lists if the business adopts and discloses to the
          public (in its privacy policy statement) a policy of not disclosing
          customer&rsquo;s personal information to third parties for their
          direct marketing purposes unless the customer first affirmatively
          agrees to the disclosure, as long as the business maintains and
          discloses this policy. Rather, the business may comply with the law by
          notifying the customer of his or her right to prevent disclosure of
          personal information and providing a cost free means to exercise that
          right.
        </p>

        <p>
          As stated in our privacy policy statement, we do not share information
          with third parties for their direct marketing purposes unless you
          affirmatively agree to such disclosure &mdash; typically by opting-in
          to receive information from a third party. To prevent disclosure of
          your personal information for use in direct marketing by a third
          party, do not opt-in to such use when you provide personal information
          on our website. Please note that whenever you opt-in to receive future
          communications from a third party, your information will be subject to
          the third party&rsquo;s privacy policies and practices. If you later
          decide that you do not want that third party to use your information,
          you will need to contact the third party directly, as we have no
          control over how third parties use information. You should always
          review the privacy policies and practices of any party that collects
          your information to determine how that party will handle your
          information.
        </p>

        <p>
          California customers may request further information about our
          compliance with this law by emailing hello@bookingbucket.com. Please
          note that we are only required to respond to one request per customer
          each year, and we are not required to respond to requests made by
          means other than through this email address.
        </p>

        <p>California Do Not Track Disclosure:</p>

        <p>
          Our websites do not respond to &ldquo;do not track&rdquo; browser
          signals. Depending on the browser you are using, you may be able to
          choose to block third-party cookies or browse in a private browsing
          mode. Our websites are accessible even when private browsing is turned
          on. The information we collect is governed by this Privacy Policy.
        </p>

        <p>
          California Consumer Additional Information under California Consumer
          Privacy Act of 2018 (&ldquo;CCPA&rdquo;)
        </p>

        <p>
          You will not receive discriminatory treatment by us for exercising
          your privacy rights conferred on you by the CCPA.
        </p>

        <p>
          You have certain additional rights regarding your personal
          information, pursuant to California law. These include the rights to:
        </p>

        <p>
          <ul>
            <li>
              Receive a list of the categories of personal information we have
              collected about you;
            </li>
            <li>
              Receive a list of the categories of sources from which your
              personal information was collected;
            </li>
            <li>
              Receive a list of the categories of your personal information that
              we have disclosed for a business purpose;
            </li>
            <li>
              Receive a list of the categories of third parties to whom your
              personal information was disclosed for a business purpose;
            </li>
            <li>
              Know the business or commercial purpose for collecting your
              personal information;
            </li>
            <li>
              Receive a list of specific personal information we collected and
              for what purposes;
            </li>
            <li>
              Have your personal information deleted by us and direct our
              service providers to delete your information. However, it may be
              retained pursuant to an allowed exception if applicable;
            </li>
            <li>
              Know if personal information was collected from sources other than
              from you and the categories of sources from which the information
              was obtained; and
            </li>
            <li>
              Receive your personal information in a usable electronic format
              and transmit it to a third party (right to data portability).
            </li>
          </ul>
        </p>

        <p>
          Please submit your request for information or deletion of information
          concerning you as noted below. In order to provide you with your
          requested information or to delete the information which we have
          concerning you, we must be able to verify that you are the person
          requesting the information or deletion. With your request please
          provide at least two items of information which you have previously
          provided to us for us to use for verification of your identity. If
          this is not sufficient, we will contact you for additional means of
          verification.
        </p>

        <p>
          You may designate an authorized agent to make a request for you. If
          your agent has a power of attorney pursuant to California Probate Code
          sections 4000 to 4465, please provide proof to the same. If your agent
          does not have a power of attorney pursuant to California Probate Code
          sections 4000 to 4465, we will require you to provide the authorized
          agent written permission to make the request, verify your own identity
          directly with us and require your agent to submit proof that they are
          authorized by you to act on your behalf.
        </p>

        <p>
          For information concerning you or for deletion of information
          concerning you, please email hello@bookingbucket.com.
        </p>

        <h5>Jurisdiction and Contact Information</h5>

        <p>
          Booking Bucket, LLC is a Canadian limited liability company. Our
          websites are controlled and operated from Canada. If you are an
          individual from the European Union, Iceland, Lichtenstein, Norway or
          any other jurisdiction with laws or regulations governing personal
          data collection, use, and disclosure that differ from Canada or United
          States laws, please be advised that we may store the information we
          collect in the United States or in other countries where we or our
          third-party service providers have operations. Personal data may also
          be transferred from the country of your residence to other countries,
          including Canada and the United States.
        </p>

        <h5>Contact Information</h5>

        <p>
          If you have any questions or suggestions regarding our Privacy Policy,
          please contact us by email at hello@bookingbucket.com
          <br />
        </p>
      </BodyTextWrapper>
    </SectionContainer>
  </StyledSection>
)

export default PrivacyPolicy

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.white};
`

const HeaderTextWrapper = styled.div`
  margin: 0;
  text-align: left;
  width: 100%;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    ${props => props.theme.fontSize.larger};
    font-weight: ${props => props.theme.fontWeight.bold};
    color: ${props => props.theme.color.black};
    line-height: 65px;
  }
`

const BodyTextWrapper = styled.div`
  h4 {
    color: ${props => props.theme.color.black};
    font-weight: ${props => props.theme.fontWeight.semibold};
    line-height: 43px;
  }
  h5 {
  }
  h6 {
    margin-top: 0px;
    font-style: italic;
    font-size: 24px;
    font-weight: ${props => props.theme.fontWeight.normal};
  }

  h7 {
    font-size: 20px;
    font-weight: ${props => props.theme.fontWeight.normal};
    color: ${props => props.theme.color.black};
    text-decoration: underline;
  }

  p {
    color: ${props => props.theme.color.black};
    ${props => props.theme.fontSize.xsmall};
    line-height: 21px;
  }

  ul {
    line-height: 21px;
  }
`
const ButtonWrapper = styled.div`
  width: 100%;
  text-align: left;
`
const BackButton = styled.button`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.xlarge};
  color: ${props => props.theme.color.black};
  letter-spacing: 1px;
  height: 10px;
  display: block;
  margin-bottom: 32px;

  outline: 0px;

  &:active {
    filter: drop-shadow(0px 0px 1px #00000066);
    transform: translateY(3px);
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
