import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"
import PrivacyPolicy from "../components/sections/privacy"

const title = "Privacy Policy - Booking Bucket"

const meta = [
]

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title={title} meta={meta} />
    <PrivacyPolicy />
  </Layout>
)

export default PrivacyPolicyPage
